import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { Title2, Flex } from './UI'
//import Crosshair from '../svg/crosshair.svg'
import Search from '../svg/search.svg'
import { breakpoints } from '../utils/helper'
import useLocalStorage from '../utils/useLocalStorage'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete'
import _ from 'lodash'

const Wrapper = styled(Flex)`
    width: 300px;
    height: 200px;
    padding: 0 18px;
    background-color: ${props => props.backgroundColor};
    border-radius: 18px;
    flex-direction: column;
    margin: 0 auto;
    
    @media ${breakpoints.mobileS} {
        width: 314px;
        height: 200px;
        padding: 0 30px;
    }
    
    @media ${breakpoints.tablet} {
        position: absolute;
        right: 10%;        
    }
    
    @media ${breakpoints.desktop} {
        width: 420px;
        height: 240px;
        padding: 0 60px;
        margin-top: -60px;
    }
    
    @media ${breakpoints.large} {
        right: 15%;
    }
    
    h2 {
        margin-bottom: 12px;
        font-size: 1.625rem;
        
        @media ${breakpoints.large} {
            margin-bottom: 30px;
            font-size: 1.875rem;
        }
    }
`

const Input = styled.input`
    height: 60px;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    border: none;
    ${props => props.theme.fontStyle.body};
    flex: 0 1 auto;
    padding: 0 18px;
    outline: none;
`

/*const TextButton = styled.button`
    background-color: transparent;
    border: none;
    ${props => props.theme.fontStyle.h4};
    font-size: 1rem;
    color: ${props => props.theme.colors.white};
    margin-top: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-indent: 24px;
    padding: 0;
    outline: none;

    #crosshair {
        stroke: ${props => props.backgroundColor === '#000000' ? props.theme.colors.red : props.theme.colors.white};
    }

    &:hover {
        color: #FFFFFF80;
    }
`*/

const InputBox = styled(Flex)`
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    button {
        position: absolute;
        right: 6px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        z-index: 10;
    }
`

const Inner = styled(Flex)`
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: flex-start;
`

const Results = styled.div`
    margin-top: 50px;
    position: absolute;
    top: 0;
    width: 100%;
    
    div {
        padding: 12px 12px;
        cursor: pointer;
        background-color: #ffffff;
        
        &:last-child {
            border-radius: 0 0 12px 12px;    
        }
    }
    
    span {
        font-size: 0.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        display: block;
    }
`

const SearchBox = ({color, onCurrentLocation}) => {
    const [address, setAddress] = useState('')
    const [geo, setGeo] = useLocalStorage('geo', {'lat': 52.6283939, 'lng': 4.888174});

    const searchOptions = {
        location: typeof window !== 'undefined' && new window.google.maps.LatLng(52.1006501, 5.1717151),
        radius: 150000
    }
    useEffect(() => {
        if (address !== '') {
            onCurrentLocation(geo)
        }
        // eslint-disable-next-line
    }, [geo])

/*    const handleGeoPermission = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            setGeo({'lat': position.coords.latitude, 'lng': position.coords.longitude})
        }, (error) => {
            //console.log(error)
        }, {
            enableHighAccuracy: false,
            timeout: 5000,
            maximumAge: Infinity
        })
    }*/

    const handleClick = () => console.log(geo)

    const handleChange = address => setAddress(address)

    const handleSelect = address => {
        setAddress(address)
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setGeo({'lat': _.get(latLng, 'lat'), 'lng': _.get(latLng, 'lng')})
                console.log(latLng)
            })
            .catch(error => console.error('Error', error))
    }

    return (
        <Wrapper backgroundColor={color}>
            <Title2 color="#ffffff">Vind een vestiging bij jou in de buurt</Title2>
            <InputBox>
                <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    searchOptions={searchOptions}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                        <Inner>
                            <Input
                                {...getInputProps({
                                    placeholder: 'woonplaats / postcode',
                                    className: 'location-search-input',
                                })}
                                id="search"
                                value={address}
                            />
                            {/*<label htmlFor="search"/>*/}
                            <Results>
                                {loading && <div>Even geduld...</div>}

                                {suggestions.map((suggestion, i) => {
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'

                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' }

                                    return (
                                        <div key={i}
                                             {...getSuggestionItemProps(suggestion, {
                                                 className,
                                                 style,
                                             })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </Results>
                        </Inner>
                    }
                </PlacesAutocomplete>
                {/*eslint-disable-next-line*/}
                <button onClick={handleClick}><Search/></button>
            </InputBox>
            {/*<TextButton onClick={handleGeoPermission} backgroundColor={color}><Crosshair/> Huidige locatie</TextButton>*/}
        </Wrapper>
    )
}

export default SearchBox
