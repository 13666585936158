import styled from 'styled-components';
import React from 'react';

const Container = styled.ul`
  margin-top: 2rem;
    padding-inline-start: 1.125rem;
`

const Item = styled.li`
    margin-bottom: 0.75rem;
`

const UpClubPerks = () =>
    <Container>
        <Item>
            Sparen voor een gratis All-in t.w.v. €19,50,-
        </Item>
        <Item>
            Profiteer van speciaal UP Club member tarief
        </Item>
        <Item>
            UP Club is een gratis lidmaatschap
        </Item>
        <Item>
            Makkelijk digitaal en veilig op je iPhone Wallet of Google Pay app
        </Item>
        <Item>
            Uitsluitend voor particulier gebruik
        </Item>
    </Container>

export default UpClubPerks
