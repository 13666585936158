import React from 'react'
import { Title, Flex, Span } from './UI'
import styled from 'styled-components'
import card from '../images/up-card.png'
import pattern from '../images/pattern-club.png'
import Button from './Button'
import { breakpoints } from '../utils/helper'
import {Link} from "gatsby";
import UpClubPerks from './UpClubPerks';

const Outer = styled(Flex)`
    flex-direction: column;
    
    @media ${breakpoints.desktop} {
        flex-direction: row;
    }
`

const CardContainer = styled.div`
    background: url(${pattern}) no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    padding: 24px 0;
    
    @media ${breakpoints.desktop} {
        height: 100vh;
        width: 25%;
        max-height: 900px;
        background-image: none;
        background-color: ${props => props.theme.colors.lightGrey};
    }
`

const InfoContainer = styled(CardContainer)`
    background-image: none;
    background-color: ${props => props.theme.colors.white};
    padding: 0 30px;
    
    @media ${breakpoints.mobileM} {
        padding: 0 60px;
    }
    
    @media ${breakpoints.tablet} {
        padding: 0 100px;
    }
    
    @media ${breakpoints.desktop} {
        background: url(${pattern}) no-repeat;
        background-size: cover;
    }
`

const Inner = styled.div`
    padding: 64px 0;
    
    @media ${breakpoints.desktop} {
        padding: 0;
        max-width: 430px;
        width: 100%;
    }
    
    span {
        display: block;
        margin-top: 30px;
        
        @media ${breakpoints.desktop} {
            width: 95%;
        }
    }
    
    button {
        margin-top: 24px;
    }
`

const MegaTitle = styled(Title)`
    font-size: 60px;
    line-height: 60px;
    color: #000000;
    white-space: pre-wrap;
    
    @media ${breakpoints.large} {
        font-size: 100px;
        line-height: 100px;
    }
    
    b {
        color: ${props => props.theme.colors.red};
        
    }
`

const Image = styled.img`
   width: 100%;
   max-width: 300px;
`

const InteralLink = styled(Link)`
    text-decoration: none;
    
    &:hover {
        text-decoration: none;
    }
`

const UPCard = () =>
    <Outer>
        <CardContainer>
            <Image src={card} alt="Club UP member card" width="100%" height="auto"/>
        </CardContainer>
        <InfoContainer>
            <Inner>
                <MegaTitle>Word lid van de <b>UP Club</b></MegaTitle>
                <Span>
                    Als lid van de UP Club spaar je gratis voor korting of een upgrade op je wasbeurt. Bij elke wassing
                    spaar je stamps en bij 25 stamps ontvang je een gratis wasprogramma!
                    <UpClubPerks/>
                </Span>
                <InteralLink to="/up"><Button>Meld je aan</Button></InteralLink>
            </Inner>
        </InfoContainer>
    </Outer>

export default UPCard
